<template>
    <b-modal ref="addItemToWriteOff" :title="$t('material.adding_ingredient')" size="lg" @ok="validate">
        <validation-observer ref="validationAddItem">
            <div>
                <b-form-group :label="$t('general.ingredient')">
                    <validation-provider #default="{ errors }" :name="$t('general.ingredient')" rules="required">
                        <v-select appendToBody v-model="selectedIngredient" :options="showIngredients" label="name" @option:selected="changeDefault" :clearable="false"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <div class="row">
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.quantity')">
                            <validation-provider #default="{ errors }" :name="$t('general.ingredient')" rules="required|min_value:0|max_value:1000000|decimalCustom:0|mustBePositive:true">
                                <b-form-input type="number" v-model.number="quantity" :disabled="!selectedIngredient" lazy max="1000000"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.packaging')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.packaging')" :rules="getQuantityRules">
                                <b-form-input type="number" v-model.number="packaging" :disabled="!selectedIngredient"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.unit')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.unit')" rules="required">
                                <v-select v-model="addItem.packaging_unit" :disabled="!selectedIngredient" appendToBody label="name" :options="types" class="unit-select" :clearable="false"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </validation-observer>

        <template #modal-footer>
            <b-button variant="secondary" @click="hide">
                {{ $t('general.cancel') }}
            </b-button>
            <b-button variant="primary" @click="addNewItem">
                <span v-if="!editing">{{ $t('general.add') }}</span>
                <span v-else>{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>


<script>
    import vSelect from 'vue-select'
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {decimalCustom, minValue, required} from '@validations'
    import {minNumber} from '@core/utils/validations/validations'

    export default {
        components:{
            vSelect,
            BModal,
            BFormGroup,
            BFormInput,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            usedIngredients: {
                type: Array
            },
            averagePrices: {
                type: Array
            }
        },
        data() {
            return {
                showIngredients: [],
                selectedIngredient: null,
                addItem: {
                    id: '',
                    packaging_unit: '',
                    packaging: '',
                    quantity: '',
                    price: ''
                },
                addItemInternal: {
                    quantity: ''
                },
                types: [],
                editing: false,
                required,
                decimalCustom,
                minNumber,
                minValue,
                num_of_decimals: 2
            }
        },
        computed: {
            packaging: {
                get() {
                    return this.addItem.packaging
                },
                async set(newValue) {
                    this.addItem.packaging = newValue
                    if (!(await this.validate())) {
                        return
                    }
                    this.calcAllValues()
                }
            },
            quantity: {
                get() {
                    return this.addItem.quantity
                },
                async set(newValue) {
                    if (newValue < 0) {
                        this.addItem.quantity = newValue
                        return
                    }
                    this.addItem.quantity = newValue
                    this.addItemInternal.quantity = newValue
                    if (!(await this.validate())) return
                    this.calcAllValues()
                }
            },
            money_types() {
                return this.$store.getters['ingredients/moneyTypes']
            },
            weight_types() {
                return this.$store.getters['ingredients/weightTypes']
            },
            volume_types() {
                return this.$store.getters['ingredients/volumeTypes']
            },
            piece_types() {
                return this.$store.getters['ingredients/pieceTypes']
            },
            amount_types() {
                return this.$store.getters['ingredients/amountTypes']
            },
            getQuantityRules() {
                if (this.addItem.packaging_unit !== null && this.addItem.packaging_unit) {
                    if (this.addItem.packaging_unit.name === 'g' || this.addItem.packaging_unit.name === 'mL') {
                        return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                    } else if (this.addItem.packaging_unit.name === 'dag' || this.addItem.packaging_unit.name === 'dL' || this.addItem.packaging_unit.name === 'Kos') {
                        return 'required|decimalCustom:2|mustBePositive:true|min_number:0'
                    } else if (this.addItem.packaging_unit.name === 'kg' || this.addItem.packaging_unit.name === 'L') {
                        return 'required|decimalCustom:3|mustBePositive:true|min_number:0'
                    } else if (this.addItem.packaging_unit.name === 'EUR') {
                        return 'required|decimalCustom:6|mustBePositive:true|min_number:0'
                    }

                    return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                } else {
                    return ''
                }
            }
        },
        methods:{
            getNumOfDecimalPlaces(data) {
                const dataConverted = String(this.$numberRounding(data, 6))
                const dataLength = dataConverted.length
                let decimals = 0
                for (let i = 0; i <= 5; i++) {
                    if (dataConverted[dataLength - i - 1] === '0') {
                        decimals++
                    } else {
                        break
                    }
                }
                return 6 - decimals
            },
            setNumOfDecimalPlaces(newValue) {
                this.num_of_decimals = String(newValue).split('.')[1] ? String(newValue).split('.')[1].length : 2
                if (this.num_of_decimals <= 2) {
                    this.num_of_decimals = 2
                } else if (this.num_of_decimals >= 6) {
                    this.num_of_decimals = 6
                }
            },
            calcAllValues() {
                if (this.addItem.quantity < 0) this.addItem.quantity = 0
            },
            async validate() {
                return await this.$refs.validationAddItem.validate()
            },
            async addNewItem() {
                if (!(await this.validate())) return

                const packaging = this.addItem.packaging * this.addItem.packaging_unit.factor
                const price = this.selectedIngredient.average_price * packaging * this.addItem.quantity
                
                if (this.addItem.id === undefined) { // je undefined
                    this.addItem.id = Math.random().toString(16).substring(2, 8)
                }
                const payload = {
                    id: this.addItem.id,
                    packaging_unit: this.addItem.packaging_unit.name,
                    packaging,
                    quantity: parseInt(this.addItem.quantity),
                    price,
                    ingredient_id: this.selectedIngredient.id
                }

                this.$emit('addWriteOffItem', payload, this.editing)


                this.hide()
            },
            changeDefault() {
                if (!this.selectedIngredient) {
                    return
                }

                this.addItem.quantity = '1'
                this.addItem.packaging = ''
                this.addItem.price_per_unit = ''
                this.addItem.discount = 0
                this.addItemInternal = {
                    price_per_unit: '',
                    price_per_unit_bruto: '',
                    total_price_neto: '',
                    total_price: '',
                    discount: '',
                    quantity: ''
                }
                this.calcAllValues()

                if (this.selectedIngredient.amount_type === 'piece') {
                    this.addItem.packaging_unit = this.piece_types.find(ele => ele.factor === 100)
                    this.types = this.piece_types
                } else if (this.selectedIngredient.amount_type === 'volume') {
                    this.addItem.packaging_unit = this.volume_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.volume_types
                } else if (this.selectedIngredient.amount_type === 'weight') {
                    this.addItem.packaging_unit = this.weight_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.weight_types
                } else if (this.selectedIngredient.amount_type === 'money') {
                    this.addItem.packaging_unit = this.money_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.money_types
                }

                if (this.addItem.packaging_unit) {
                    this.addItem.packaging = this.selectedIngredient.packaging / this.addItem.packaging_unit.factor
                }

                if (this.selectedIngredient.average_price > 0) {
                    this.addItem.price_per_unit = this.$numberRounding(this.selectedIngredient.average_price)
                    this.addItemInternal.price_per_unit = this.selectedIngredient.average_price
                    this.calcAllValues()
                } else {
                    this.addItem.price_per_unit = null
                }


                this.$refs.validationAddItem.reset()
            },
            findTypeOfIngredient() {
                if (!this.selectedIngredient) {
                    return
                }

                let factor = 1
                let type = null
                if (this.addItem.packaging_unit === 'mL' || this.addItem.packaging_unit === 'dL' || this.addItem.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === this.addItem.packaging_unit)
                    this.types = this.volume_types
                } else if (this.addItem.packaging_unit === 'g' || this.addItem.packaging_unit === 'dag' || this.addItem.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === this.addItem.packaging_unit)
                    this.types = this.weight_types
                }  else if (this.addItem.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === this.addItem.packaging_unit)
                    this.types = this.money_types
                } else {
                    type = this.piece_types.find(ele => ele.name === this.addItem.packaging_unit)
                    this.types = this.piece_types
                }

                if (!type) {
                    return
                }

                this.addItem.packaging_unit = type

                factor = type.factor

                this.addItem.packaging = this.addItem.packaging / factor
            },
            async open(data) {
                if (!data) {
                    this.editing = false
                    this.num_of_decimals = 2
                    const rand = Math.random().toString(16).substring(2, 8)
                    this.selectedIngredient = null
                    this.addItem = {
                        id: rand,
                        packaging_unit: '',
                        packaging: '',
                        quantity: ''
                    }
                    this.addItemInternal = {
                        price_per_unit: 0,
                        price_per_unit_bruto: 0,
                        total_price_neto: 0,
                        total_price: 0,
                        discount: '',
                        quantity: '1'
                    }
                } else {
                    this.editing = true
                    this.showIngredients = this.averagePrices.filter(ingredient => !this.usedIngredients.includes(ingredient.id)
                        && ingredient.disabled !== true)
                    const editItem = JSON.parse(JSON.stringify(data))
                    this.calculateItem(editItem)
                }

                this.showIngredients = this.averagePrices.filter(ingredient => !this.usedIngredients.includes(ingredient.id)
                    && ingredient.disabled !== true)
                this.$refs.addItemToWriteOff.show()
            },
            hide() {
                this.$refs.addItemToWriteOff.hide()
            },
            calculateItem(data) {
                this.addItem = {
                    id: data.id,
                    packaging_unit: data.packaging_unit,
                    packaging: data.packaging,
                    quantity: data.quantity
                }

                this.selectedIngredient = this.averagePrices.find(ele => ele.id === data.ingredient_id)
                this.findTypeOfIngredient()

                this.addItemInternal.price_per_unit = data.price_per_unit
                this.addItemInternal.discount = data.discount * 10000
                this.addItemInternal.quantity = data.quantity

                this.calcAllValues()
            }
        }
    }
</script>

<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>

<style>
.unit-select .vs__selected-options {
  padding: unset !important;
}

</style>